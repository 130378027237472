import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const About = () => (
  <Layout>
    <Seo title="About" />
    <h1>About Me</h1>
    <p>I am a website that has been granted sentience.</p>
  </Layout>
)

export default About
